var $iEn1Z$axios = require("axios");
var $iEn1Z$uuid = require("uuid");

function $parcel$exportWildcard(dest, source) {
  Object.keys(source).forEach(function(key) {
    if (key === 'default' || key === '__esModule' || dest.hasOwnProperty(key)) {
      return;
    }

    Object.defineProperty(dest, key, {
      enumerable: true,
      get: function get() {
        return source[key];
      }
    });
  });

  return dest;
}
function $parcel$interopDefault(a) {
  return a && a.__esModule ? a.default : a;
}
function $parcel$export(e, n, v, s) {
  Object.defineProperty(e, n, {get: v, set: s, enumerable: true, configurable: true});
}

var $b80ef5563dba82ee$exports = {};

$parcel$export($b80ef5563dba82ee$exports, "AxiosClientConfigurator", () => $b80ef5563dba82ee$export$e86c67606777b7aa);
$parcel$export($b80ef5563dba82ee$exports, "AxiosClient", () => $b80ef5563dba82ee$export$ad5a3977633fb854);


class $b80ef5563dba82ee$export$e86c67606777b7aa {
    constructor(baseUrl, otherConfig = {}, axiosOverrides){
        this._beforeRequestOps = new Map([]);
        this._retryRequestOnFailedOps = new Map([]);
        this._config = {};
        this._baseUrl = baseUrl;
        this._config = otherConfig;
        this._axios = axiosOverrides || (0, ($parcel$interopDefault($iEn1Z$axios)));
    }
    beforeRequest(fn) {
        const _id = (0, $iEn1Z$uuid.v4)();
        this._beforeRequestOps.set(_id, fn);
        return this;
    }
    retryRequestOnFailed(fn) {
        const _id = (0, $iEn1Z$uuid.v4)();
        this._retryRequestOnFailedOps.set(_id, fn);
        return this;
    }
    getConfig() {
        return {
            baseAxios: this._axios,
            config: this._config || {},
            baseUrl: this._baseUrl,
            beforeRequestOps: Array.from(this._beforeRequestOps.values()),
            retryRequestOps: Array.from(this._retryRequestOnFailedOps.values())
        };
    }
}
const $b80ef5563dba82ee$var$makeAxiosInstance = (config)=>{
    const _config = config.getConfig();
    const _axios = _config.baseAxios.create({
        ..._config.config,
        baseURL: _config.baseUrl
    });
    _config.beforeRequestOps.forEach((fn)=>{
        _axios.interceptors.request.use((config)=>{
            return new Promise((resolve, reject)=>{
                try {
                    fn(config, (newConfig)=>{
                        resolve(newConfig);
                    });
                } catch (err) {
                    reject(err);
                }
            });
        });
    });
    _axios.interceptors.response.use(undefined, (err)=>{
        let __config = null;
        return new Promise((resolve, reject)=>{
            const { config: config } = err;
            __config = config || {};
            if (__config.retry === 1) reject(err);
            else {
                let _retry = false;
                const finalResponse = ()=>{
                    if (_retry) {
                        __config.retry = 1;
                        setTimeout(()=>{
                            _axios(__config).then(resolve).catch(reject);
                        }, 1000);
                    } else reject(err);
                };
                const processRetry = (_index)=>{
                    if (_index >= _config.retryRequestOps.length) {
                        finalResponse();
                        return;
                    }
                    _config.retryRequestOps[_index](err, __config, (newConfig)=>{
                        __config = newConfig;
                        _retry = true;
                        processRetry(_index + 1);
                    }, ()=>{
                        processRetry(_index + 1);
                    });
                };
                processRetry(0);
            }
        });
    });
    return _axios;
};
const $b80ef5563dba82ee$var$makeRequest = (config, path, method, initialVariables)=>{
    let _internalData;
    let _isLoading = false;
    let _dataListeners = new Map([]);
    let _errorListeners = new Map([]);
    let _loadingListeners = new Map([]);
    const data = ()=>{
        return _internalData;
    };
    const _internalOnData = (data)=>{
        _internalData = data;
        Array.from(_dataListeners.values()).forEach((fn)=>{
            fn(data);
        });
    };
    const _internalOnFailed = (err)=>{
        Array.from(_errorListeners.values()).forEach((fn)=>{
            fn(err);
        });
    };
    const _internalOnLoadingChange = (status)=>{
        _isLoading = status;
        Array.from(_loadingListeners.values()).forEach((fn)=>{
            fn(status);
        });
    };
    const makePathWithData = (path, data)=>{
        return `${path}?${new URLSearchParams(data).toString()}`;
    };
    const fetch = (data, headers)=>{
        return new Promise((resolve, reject)=>{
            _internalOnLoadingChange(true);
            const _data = data || initialVariables || {};
            const finalUrl = method === "GET" || method === "DELETE" ? makePathWithData(path, _data) : path;
            const finalData = method === "GET" || method === "DELETE" ? {} : _data;
            $b80ef5563dba82ee$var$makeAxiosInstance(config).request({
                method: method,
                url: finalUrl,
                data: finalData,
                headers: {
                    ...headers
                }
            }).then((res)=>{
                _internalOnData(res.data);
                resolve(res.data);
            }).catch((err)=>{
                _internalOnFailed(err);
                reject(err);
            }).finally(()=>{
                _internalOnLoadingChange(false);
            });
        });
    };
    const isLoading = ()=>{
        return _isLoading;
    };
    const onLoadingChange = (fn)=>{
        const _id = (0, $iEn1Z$uuid.v4)();
        _loadingListeners.set(_id, fn);
        return ()=>{
            _loadingListeners.delete(_id);
        };
    };
    const onLoadingChangeOnce = (fn)=>{
        const remove = onLoadingChange((status)=>{
            fn(status);
            remove();
        });
    };
    const onData = (fn)=>{
        const _id = (0, $iEn1Z$uuid.v4)();
        _dataListeners.set(_id, fn);
        return ()=>{
            _dataListeners.delete(_id);
        };
    };
    const onDataOnce = (fn)=>{
        const remove = onData((data)=>{
            fn(data);
            remove();
        });
    };
    const onError = (fn)=>{
        const _id = (0, $iEn1Z$uuid.v4)();
        _errorListeners.set(_id, fn);
        return ()=>{
            _errorListeners.delete(_id);
        };
    };
    const onErrorOnce = (fn)=>{
        const remove = onError((err)=>{
            fn(err);
            remove();
        });
    };
    return {
        data: data,
        fetch: fetch,
        isLoading: isLoading,
        onLoadingChange: onLoadingChange,
        onLoadingChangeOnce: onLoadingChangeOnce,
        onData: onData,
        onDataOnce: onDataOnce,
        onError: onError,
        onErrorOnce: onErrorOnce
    };
};
class $b80ef5563dba82ee$export$ad5a3977633fb854 {
    constructor(config){
        this._config = config;
    }
    get(path, config = {}) {
        return $b80ef5563dba82ee$var$makeRequest(this._config, path, "GET", config.data);
    }
    post(path, config = {}) {
        return $b80ef5563dba82ee$var$makeRequest(this._config, path, "POST", config.data);
    }
    patch(path, config = {}) {
        return $b80ef5563dba82ee$var$makeRequest(this._config, path, "PATCH", config.data);
    }
    put(path, config = {}) {
        return $b80ef5563dba82ee$var$makeRequest(this._config, path, "PUT", config.data);
    }
    delete(path, config = {}) {
        return $b80ef5563dba82ee$var$makeRequest(this._config, path, "DELETE", config.data);
    }
}


$parcel$exportWildcard(module.exports, $iEn1Z$axios);
$parcel$exportWildcard(module.exports, $b80ef5563dba82ee$exports);


