import {
    AxiosClient,
    AxiosClientConfigurator,
  } from "@xatom/axios";
  import { WFComponent } from "@xatom/core";

export const fetchSingleUseCode = () => {
    const getCodeBtn = new WFComponent<HTMLDivElement>(`.get-code-button`);
    const getCodeBtnText = new WFComponent<HTMLDivElement>(`.get-code-button-text`);
    const getCodeBtnLoader = new WFComponent<HTMLDivElement>(`.get-code-button-loader`);
    const singleUseCode = new WFComponent<HTMLDivElement>(`.is-single-use-code`);
    const singleUseCodeName = new WFComponent<HTMLDivElement>(`.single-use-code_provider-name`);
    const sucText = new WFComponent<HTMLDivElement>(`.single-use-code-text`);
    const copyCodeBtn = new WFComponent<HTMLDivElement>(`.copy-code-button`);
    const useCodeBtn = new WFComponent<HTMLDivElement>(`.use-this-code`);
    const iconCopy = new WFComponent<HTMLDivElement>(`.copy-code-button > .icon-copy`);
    const iconCheck = new WFComponent<HTMLDivElement>(`.copy-code-button > .icon-check`);
  
    // Define a click event handler function
    const fetchSuc = (e) => {
      e.preventDefault();
      console.log("Fetching code");
      let ucnValue = sessionStorage.getItem('ucn');
      const ucn = ucnValue;
      const url = window.location.href;
      const providerString = singleUseCodeName.getText();
      const discountProviderName = providerString;
      
      let endpoint = window.location.href.startsWith('http://localhost:3020') 
      ? process.env.LOCAL 
      : process.env.API_BASE_URL;
  
      // Configure the base URL for your API
      const axiosConfigurator = new AxiosClientConfigurator(
        endpoint
      );
  
      const axiosClient = new AxiosClient(axiosConfigurator);
  
      // Define the expected response format
      interface singleUseCodeResponse {
        code: string; 
        success: boolean;
        message?: string;
      }
  
      const fetchCodeRequest = axiosClient.post<singleUseCodeResponse>("/api/fetchSuc", {
        data: {ucn, url, discountProviderName},
      });
  
      fetchCodeRequest.onLoadingChange((status) => {
        if (status === true){
          getCodeBtnText.addCssClass('hide');
          getCodeBtnLoader.removeCssClass('hide');
        } else {
          getCodeBtnText.removeCssClass('hide');
          getCodeBtnLoader.addCssClass('hide');
        }
      });
  
      fetchCodeRequest.onData((response) => {
        console.log("Response:", response);
        sucText.setText(response.code);
        singleUseCode.removeCssClass('hide');
        useCodeBtn.removeCssClass('hide');
        getCodeBtn.addCssClass('hide');
      });
  
      fetchCodeRequest.onError((error) => {
        console.log('Error:', error.response.data.error);
        const codeError = new WFComponent<HTMLDivElement>(`.code-error`);
        const codeErrorText = new WFComponent<HTMLDivElement>(`.code-error-text`);
        const codeErrorTexta = codeErrorText.getElement();
        codeError.removeCssClass('hide')
        if (error.status === 429) {
          codeErrorTexta.innerHTML = `${error.response.data.error} contact support`;
        }
      });
  
      fetchCodeRequest.fetch();
    };
  
    // Attach the click event listener to the getCode button
    getCodeBtn.on("click", fetchSuc);
    
    const copyCode = async () => {
      const code = sucText.getText().trim();
      await navigator.clipboard.writeText(code);
  
      // Show success feedback
      iconCopy.addCssClass('hide');
      iconCheck.removeCssClass('hide');
  
      // Revert back after 2 seconds
      setTimeout(() => {
        iconCopy.removeCssClass('hide');
        iconCheck.addCssClass('hide');
      }, 2000);
    };
    
    // Attach the click event listener to the copyCode button
    copyCodeBtn.on("click", copyCode);
  };