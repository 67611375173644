import {
    AxiosClient,
    AxiosClientConfigurator,
  } from "@xatom/axios";
  import { onReady } from "@xatom/core";
  import { WFComponent } from "@xatom/core";
  import { WFFormComponent } from "@xatom/core";
  import { getCookie, setCookie } from "../routes";
  const { createAuth0Client } = require('@auth0/auth0-spa-js');
  
  // Initialize Auth0 Client
  const auth0Client = createAuth0Client({
    domain: 'dev-137ew6bxl6a1k0t2.uk.auth0.com',
    clientId: 'L7wY4A9Ij8kdaMLFmT8slBxApGZRLl7t',
    authorizationParams: {
        redirect_uri: 'http://localhost:3020/discounts-and-benefits',
        audience: 'https://ndc-storefront.com',
        scope: 'openid profile email'
    }
  });
  
  export const membershipCheck = () => {
    let endpoint = window.location.href.startsWith('http://localhost:3020') 
    ? process.env.LOCAL 
    : process.env.API_BASE_URL;

    const axiosConfigurator = new AxiosClientConfigurator(
        endpoint
    );
    const axiosClient = new AxiosClient(axiosConfigurator);


    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
        auth0Client.then(client => client.handleRedirectCallback())
            .then(() => history.replaceState({}, document.title, window.location.origin + window.location.pathname));
    }

  
    onReady(() => {
        const membershipCheckBtn = new WFComponent<HTMLDivElement>(".membership-check-button");
        const membershipModal = new WFComponent<HTMLDivElement>(".membership-modal");
        const modalCloseButtonEl = document.querySelector(".modal1_close-button");
        const modalBackgroundEl = document.querySelector(".modal1_background-overlay");
        const formError = new WFComponent<HTMLDivElement>(".form_error-wrapper");
        const formErrorText = new WFComponent<HTMLDivElement>(".form_error-message");
        const cards = document.querySelectorAll('.card');

  
        const loginForm = new WFFormComponent<{
            ucn: string;
            url: string;
        }>(".cardnumberform");
  
        const showError = (message) => {
            formError.setStyle({ display: "inline-flex" });
            formErrorText.setText(message);
        };
  
        const hideError = () => {
            formError.setStyle({ display: "none" });
            formErrorText.setText('');
        };
  
        const onModalClose = (e) => {
            e.preventDefault();
            if (window.location.pathname === '/discounts-and-benefits') {
                membershipModal.setStyle({ display: "none" });
                membershipModal.removeCssClass('show-modal');
                hideError();
            }
        };
  
        // Only add event listeners if the elements exist**
        if (modalCloseButtonEl) {
          const modalCloseButton = new WFComponent<HTMLAnchorElement>(".modal1_close-button");
          modalCloseButton.on('click', onModalClose);
        }
        if (modalBackgroundEl) {
          const modalBackground = new WFComponent<HTMLDivElement>(".modal1_background-overlay");
          modalBackground.on('click', onModalClose);
        }
  
        let clickedCardUrl = "";

        const parentContainer = new WFComponent<HTMLDivElement>(".discount_list"); // Replace with actual container class

        if (parentContainer) {
            parentContainer.on("click", (e) => {
                let card = (e.target as Element).closest(".card");
                if (!card) return; // Exit if the click wasn't on a card

                e.preventDefault();  // Stop default navigation
                
                clickedCardUrl = card.getAttribute("href");

                const hiddenUrlField = document.querySelector('.hiddenUrlField');
                if (hiddenUrlField) {
                    hiddenUrlField.setAttribute("value", clickedCardUrl);
                }

                if (getCookie('membershipValid') === 'true') {
                    window.location.href = clickedCardUrl;
                } else {
                    if (membershipModal) {
                        membershipModal.setStyle({ display: "block" });
                        membershipModal.addCssClass('show-modal');
                    }
                }
            });
        }

        loginForm.onFormSubmit((data) => {
            interface MembershipCheckPayload {
                ucn: string;
                url: string;
                redirectUrl: string;
                membershipValid: boolean;
            }
  
            const payload: MembershipCheckPayload = {
                ucn: data.ucn,
                url: clickedCardUrl || window.location.href,
                redirectUrl: '',
                membershipValid: false
            };
  
            const postUser = axiosClient.post<MembershipCheckPayload>("/api/membershipCheck", {
                data: payload,
            });
  
            postUser.onLoadingChange((status) => {
                if (status === true) {
                    membershipCheckBtn.setAttribute("value", "Checking card number...");
                } else {
                    membershipCheckBtn.setAttribute("value", "Submit");
                }
            });
  
            postUser.onData((data) => {
              if (data.membershipValid === true) {
                setCookie('membershipValid', 'true', 1);
                sessionStorage.setItem('ucn', data.ucn);
                
                membershipModal.setStyle({ display: "none" });
                membershipModal.removeCssClass('show-modal');

                window.location.href = data.redirectUrl || window.location.href;
              } else {
                setCookie('membershipValid', 'false', 1);
                showError(`Something went wrong, please check your card number and try again.`);
              }
              
            });
  
            postUser.onError((error) => {
                setCookie('membershipValid', 'false', 1);
                if (error.status === 404) {
                    showError('Card number not found.');
                } else if (error.status === 400) {
                    showError('Your unique card number is required');
                } else if (error.message === 'Timeout') {
                    showError('Request timed out. Please try again.');
                } else {
                    showError('Something went wrong, please try again.');
                }
            });
  
            postUser.fetch(payload);
        });
    });
  };